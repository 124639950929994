<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                    " " +
                    $t("product.product")
                }}</span>
              </v-card-title>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    persistent-hint
                    v-model="editedItem.productName"
                    :rules="[rules.required]"
                    :label="$t('product.productName')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    persistent-hint
                    v-model="editedItem.version"
                    :rules="[rules.required]"
                    :label="$t('product.version')"
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    persistent-hint
                    v-model="editedItem.description"
                    :label="$t('product.description')"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      ></confirm-dialog>
      <v-data-table
        :headers="headers"
        :items="products"
        :search="search"
        :items-per-page="5"
        :loading="loading"
        :loading-text="$t('loading')"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="dialog = !dialog">
              {{ $t("add") }}
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-2"
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      loading: true,
      valid: true,
      valid1: true,
      dialog: false,
      dialogDelete: false,
      dialogChangePassword: false,
      editedIndex: -1,
      search: "",
      headers: [
        { text: this.$t("product.productName"), value: "productName" },
        { text: this.$t("product.description"), value: "description" },
        { text: this.$t("product.version"), value: "version" },
        { text: "", value: "actions" },
      ],
      products: [],
      editedItem: {},
      defaultItem: {
        productName: "",
        description: "",
        version: 0,
      },
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },

  created() {
    this.loading = true;
    this.refreshTable();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.products[this.editedIndex];
      axios
        .delete("Products/Delete?id=" + deleteItem.productGuid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    refreshTable() {
      axios
        .get("Products/Get")
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.products[this.editedIndex];
          updatedItem.productName = this.editedItem.productName;
          updatedItem.description = this.editedItem.description;
          updatedItem.version = this.editedItem.version;

          axios
            .post("Products/Save", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.products[this.editedIndex], this.editedItem);
        } else {
          axios
            .post("Products/Save", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
